import { getDefaultTheme } from '../utils/functions/themeFunctions'
import logoWhite from '../assets/img/logo.svg'

const LoadingPage = () => {
  const theme = getDefaultTheme()
  const isDarkMode = theme !== 'light'
  let logo = logoWhite

  /*
   * In this page we use inline styles because if we use some classes
   * or import any css file, it will not be displayed faster enough to
   * show in pages loading
   */
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: isDarkMode ? 'black' : '#f0f2f5',
        overflow: 'hidden',
        zIndex: 100,
      }}
    >
      <img alt='Img loading splash' height='125' src={logo} />
    </div>
  )
}

export default LoadingPage
