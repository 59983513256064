import { LOGIN, LOGOUT, SET_EMAIL } from './constants'

export const login = (user, token) => ({
  type: LOGIN,
  user,
  token,
})

export const logout = () => ({
  type: LOGOUT
})

export const setEmail = (email) => ({
  type: SET_EMAIL,
  email,
})