import Icon from '@ant-design/icons'
import { ReactComponent as Trailer } from './trailer.svg'
import { ReactComponent as Sun } from './sun.svg'
import { ReactComponent as Moon } from './moon.svg'
import { ReactComponent as Customer } from './customer.svg'
import { ReactComponent as BoxArchive } from './boxArchive.svg'
import { ReactComponent as LoadPackage } from './loadPackage.svg'
import { ReactComponent as CarWash } from './carWash.svg'
import { ReactComponent as Bell } from './bell.svg'
import { ReactComponent as BellRinging } from './bellRinging.svg'
import { ReactComponent as Fr } from './fr.svg'
import { ReactComponent as Nd } from './nd.svg'

const createIcon = (iconSvg) => (props) =>
  <Icon component={iconSvg} {...props} />

export const TrailerIcon = createIcon(Trailer)
export const SunIcon = createIcon(Sun)
export const MoonIcon = createIcon(Moon)
export const CustomerIcon = createIcon(Customer)
export const BoxArchiveIcon = createIcon(BoxArchive)
export const LoadPackageIcon = createIcon(LoadPackage)
export const CarWashIcon = createIcon(CarWash)
export const BellIcon = createIcon(Bell)
export const BellRingingIcon = createIcon(BellRinging)
export const FrIcon = createIcon(Fr)
export const NdIcon = createIcon(Nd)
