import { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { Breadcrumb, Button, Divider, Layout, Menu, Popover, Space } from 'antd'
import { LogoutOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons'

import ThemeSwitch from '../../components/util/ThemeSwitch'
import NotFoundPage from '../../views/NotFoundPage'
import logo from '../../assets/img/logo.svg'
import wheatRight from '../../assets/img/wheatRight.svg'
import NameAvatar from '../../components/avatar/NameAvatar'
import DrawerForm from '../../components/drawer/DrawerForm'

import {
  findActiveTab,
  findMainRoutesByUserRoles,
  generateLinks,
  generateRoutes,
  useActivePaths,
  compareActivePath,
} from '../../utils/functions/routeUtils'
import { ROLES_CONST } from '../../utils/constants/roleConstants'
import { selectUser } from '../../redux/session/selectors'
import { logout } from '../../redux/session/actions'
import { mainDefaultRoute, mainRoutes, myAccountRoute } from '../../routes'

import './MainLayout.css'

const { Sider, Content } = Layout

const MainLayout = ({ user, handleLogout }) => {
  const [collapsed, setCollapsed] = useState(false)

  const accessRoutes = findMainRoutesByUserRoles(user.roles, mainRoutes)
  const activePaths = useActivePaths()
  const activeTab = findActiveTab(accessRoutes, activePaths)

  useEffect(() => {
    if (!user.roles) handleLogout()

    // eslint-disable-next-line
  }, [user])

  const MainMenu = (
    <>
      <Link to={mainDefaultRoute}>
        <div className='main-layout__main-logo-container'>
          <img src={logo} alt='Brand logo' className='main-layout__app-logo' />
        </div>
      </Link>
      <Menu mode='inline' theme='light' selectedKeys={activePaths}>
        {generateLinks(accessRoutes, true)}
      </Menu>
      <div style={{ color: 'rgba(255, 255, 255, 0.65)' }}>
        <Divider />
      </div>
      <div className='d-flex justify-content-center'>
        <ThemeSwitch />
      </div>
    </>
  )

  const DrawerMenu = ({ closeDialog }) => (
    <Menu
      theme='light'
      mode='vertical'
      selectedKeys={activePaths}
      onClick={closeDialog}
    >
      {MainMenu}
    </Menu>
  )

  const UserMenu = (
    <>
      <p className='text-center'>
        {user.firstName} {user.lastName}
      </p>
      <Divider className='my-1' />
      <Link to={myAccountRoute}>
        <Button className='text-left' type='text' block icon={<UserOutlined />}>
          My account
        </Button>
      </Link>
      <Button
        className='text-left'
        type='text'
        block
        onClick={handleLogout}
        icon={<LogoutOutlined />}
      >
        Logout
      </Button>
    </>
  )

  let activeLogistic = compareActivePath({
    activePath: activePaths,
    comparePath: '/main/logisticDashboard',
  })

  useEffect(() => {
    setCollapsed(activeLogistic)
  }, [activeLogistic])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <span className='d-none d-md-inline'>
        <Sider
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          collapsible
        >
          {MainMenu}
        </Sider>
      </span>
      <Layout>
        <Content className='main-layout__background-texture'>
          <div className='d-none d-md-block main-layout__layer-wheat-right'>
            <img src={wheatRight} alt='wheat' />
          </div>
          <div className='container-fluid my-4 main-layout__content'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                {activeTab && (
                  <>
                    <h1 className='mb-0'>
                      {activeTab.icon} {activeTab.name}
                    </h1>
                    <Breadcrumb>
                      {activeTab.path.split('/').map((path) => (
                        <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>
                      ))}
                    </Breadcrumb>
                  </>
                )}
              </div>
              <Space>
                <Popover
                  content={UserMenu}
                  placement='leftTop'
                  trigger={['click', 'hover']}
                >
                  <div className='d-flex align-items-center'>
                    <span className='mr-2'>
                      {ROLES_CONST[user.roles[0]]?.value}
                    </span>
                    <NameAvatar name={user.firstName} />
                  </div>
                </Popover>
                <span className='d-inline d-md-none'>
                  <DrawerForm
                    drawerTitle='Menu'
                    icon={<MenuOutlined />}
                    bodyStyle={{ padding: '0' }}
                  >
                    <DrawerMenu />
                  </DrawerForm>
                </span>
              </Space>
            </div>
            <Divider />
            <Switch>
              {generateRoutes(accessRoutes)}
              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state) => ({ user: selectUser(state) })

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
