import { Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'

import AuthHeader from '../../components/headers/AuthHeader'
import NotFoundPage from '../../views/NotFoundPage'

import { generateRoutes } from '../../utils/functions/routeUtils'
import { authRoutes } from '../../routes'

import './AuthLayout.css'

const { Content } = Layout

const AuthLayout = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AuthHeader />
      <Content className='auth-layout__background-texture'>
        <Switch>
          {generateRoutes(authRoutes)}
          <Route component={NotFoundPage} />
        </Switch>
      </Content>
    </Layout>
  )
}

export default AuthLayout
