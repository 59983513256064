import { Switch } from 'antd'
import { useThemeSwitcher } from 'react-css-theme-switcher'

import { MoonIcon, SunIcon } from '../../assets/icons'

import { persistTheme } from '../../utils/functions/themeFunctions'

const ThemeSwitch = () => {
  const { switcher, themes, currentTheme } = useThemeSwitcher()

  const isDarkMode = currentTheme !== 'light'
  const nextTheme = isDarkMode ? 'light' : 'dark'

  const toggleTheme = () => {
    switcher({ theme: isDarkMode ? themes.light : themes.dark })
    persistTheme(nextTheme)
  }

  return (
    <Switch
      onChange={toggleTheme}
      checked={isDarkMode}
      checkedChildren={<MoonIcon />}
      unCheckedChildren={<SunIcon />}
    />
  )
}

export default ThemeSwitch
