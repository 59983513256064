import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './redux/store'
import { Provider } from 'react-redux'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import momentTimezone from 'moment-timezone'

import Auth from './navigation/Auth'
import AuthLayout from './layouts/AuthLayout/AuthLayout'
import Main from './navigation/Main'
import MainLayout from './layouts/MainLayout/MainLayout'
import NotFoundPage from './views/NotFoundPage'

import { loginRoute } from './routes'
import { getDefaultTheme } from './utils/functions/themeFunctions'

import 'bootstrap-4-grid/css/grid.min.css'
import './assets/css/app.css'

const themes = {
  light: `${process.env.PUBLIC_URL}/antd-light.css`,
  dark: `${process.env.PUBLIC_URL}/antd-dark.css`,
}

momentTimezone.tz.setDefault('Canada/Eastern')

function App() {
  const theme = getDefaultTheme()

  return (
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={theme || 'light'}>
      <Provider store={store}>
        <PersistGate loading='Loading' persistor={persistor}>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path='/'
                component={() => <Redirect to={loginRoute} />}
              />
              <Auth path='/auth' component={AuthLayout} />
              <Main path='/main' component={MainLayout} />
              <Route component={NotFoundPage} />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeSwitcherProvider>
  )
}

export default App
