import { Layout, Menu } from 'antd'

import { generateLinks, useActivePaths } from '../../utils/functions/routeUtils'
import ThemeSwitch from '../util/ThemeSwitch'
import { authRoutes } from '../../routes'

const { Header } = Layout

const AuthHeader = () => {
  return (
    <Header>
      <div className='d-flex justify-content-end align-items-center'>
        <span className='mr-4'>
          <ThemeSwitch />
        </span>
        <Menu theme='light' mode='horizontal' selectedKeys={useActivePaths()}>
          {generateLinks(authRoutes)}
        </Menu>
      </div>
    </Header>
  )
}

export default AuthHeader
