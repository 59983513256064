import { Avatar } from 'antd'

const NameAvatar = ({ name, className, style, size, concatName }) => {
  return (
    <>
      <Avatar
        size={size}
        className={className}
        style={{ ...style, backgroundColor: 'var(--primary)' }}
      >
        {name.slice(0, 1)}
      </Avatar>
      {concatName && <span>{name}</span>}
    </>
  )
}

NameAvatar.defaultProps = { name: '', className: '', style: {} }

export default NameAvatar
