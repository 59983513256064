import produce from 'immer'
import { SET_FILTER, SET_SORTER } from './constants'

const initialState = {}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_FILTER:
        draft.filter = action.filter
        break
      case SET_SORTER:
        draft.sorter = action.sorter
        break
      default:
        return draft
    }
  })

export default reducer
