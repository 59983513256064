export const getArrFromObj = (arr) =>
  Object.keys(arr).map((key) => ({
    ...arr[key],
    key,
  }))

export const getArrGroupBy = (array, key, key2) => {
  return array.reduce((result, currentValue) => {
    if (key2) {
      ;(result[currentValue[key][key2]] =
        result[currentValue[key][key2]] || []).push(currentValue)
    } else {
      ;(result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue,
      )
    }
    return result
  }, {})
}

export const getFormDataFromObj = (obj) => {
  const formData = new FormData()

  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      if (typeof obj[key] === 'object') {
        formData.append(key, JSON.stringify(obj[key]))
      } else {
        formData.append(key, obj[key])
      }
    }
  })

  return formData
}

/**
 * @description - this function return color ramdomly
 **
 */

export const generateRandomColor = () => {
  const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
  return color
}

/**
 *
 * @param {*} obj - object to be converted
 * @param {*} fields - fields to be converted
 * @param {*} key - key of the object
 * @returns  - object with the key and value
 */

export const getObjOptionsBoolean = ({ obj, fields, key }) => {
  const { [key]: values, ...options } = obj
  fields.forEach((field, pos) => {
    options[field] = obj[key].includes(field)
  })
  return options
}
