import { Link, useLocation, Route } from 'react-router-dom'
import { Menu } from 'antd'
const { Item } = Menu

export const generateLinks = (routes, isSider) =>
  routes &&
  routes.map(
    (item) =>
      !item.hidden && (
        <Item key={item.path}>
          <Link to={item.pathLink || item.path}>
            {item.icon}
            <span className={isSider ? '' : 'd-none d-sm-inline ml-2'}>
              {item.name}
            </span>
          </Link>
        </Item>
      ),
  )

export const generateRoutes = (routes) =>
  routes &&
  routes.map((item) => (
    <Route key={item.path} path={item.path} component={item.component} />
  ))

export const useActivePaths = () => [useLocation().pathname]

export const getPathWithoutParam = (path) => path.split(':').shift()

export const findActiveTab = (accessRoutes, activePath) => {
  const withoutParamsRoutes = accessRoutes.map((r) => ({
    ...r,
    path: getPathWithoutParam(r.path),
  }))

  const findedActiveTab = withoutParamsRoutes.find((r) =>
    activePath[0].includes(r.path),
  )

  return findedActiveTab
}

export const findMainRoutesByUserRoles = (roles, routes) => {
  const routesObj = Object.fromEntries(
    Object.entries(routes).filter(([key]) => roles.includes(key)),
  )

  const routesArray = [].concat(...Object.values(routesObj))

  return Object.values(
    routesArray.reduce(
      (acc, cur) => Object.assign(acc, { [cur.path]: cur }),
      {},
    ),
  )
}

/**
 * @description - This function is used to compare the active route with the route that is passed as a parameter
 * @param activePath active path
 * @param comparePath path to compare
 * @returns {boolean} true if the active path is the same as the comparison path
 */

export const compareActivePath = ({ activePath, comparePath }) => {
  return activePath[0] === comparePath
}
