import { cloneElement } from 'react'
import { Drawer, Button } from 'antd'
import PropTypes from 'prop-types'

import { useVisible } from '../../utils/functions/dialogFunctions'

const DrawerForm = ({
  className,
  children,
  type,
  buttonTitle,
  drawerTitle,
  block,
  ghost,
  icon,
  width,
  disabled,
  bodyStyle,
  destroyOnClose,
  noStyle,
}) => {
  const { visible, openDialog, closeDialog } = useVisible()

  const newChildren = cloneElement(children, {
    closeDialog,
  })

  return (
    <>
      {noStyle ? (
        <span onClick={openDialog}>{buttonTitle}</span>
      ) : (
        <Button
          className={className}
          icon={icon}
          type={type}
          block={block}
          ghost={ghost}
          onClick={openDialog}
          disabled={disabled}
        >
          {buttonTitle}
        </Button>
      )}
      <Drawer
        destroyOnClose={destroyOnClose}
        width={width}
        placement='right'
        title={
          icon ? (
            <>
              {icon}&nbsp;{drawerTitle}
            </>
          ) : (
            <>{drawerTitle}</>
          )
        }
        onClose={closeDialog}
        visible={visible}
        bodyStyle={bodyStyle}
      >
        {newChildren}
      </Drawer>
    </>
  )
}

DrawerForm.defaultProps = { width: 360, noStyle: false }

DrawerForm.propTypes = {
  children: PropTypes.element.isRequired,
  type: PropTypes.string,
  buttonTitle: PropTypes.string,
  drawerTitle: PropTypes.string,
  block: PropTypes.bool,
  ghost: PropTypes.bool,
  icon: PropTypes.element,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  bodyStyle: PropTypes.object,
  destroyOnClose: PropTypes.bool,
}

export default DrawerForm
