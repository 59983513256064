import { getArrFromObj } from './../functions/converterFunctions'

export const TASK_USER_GROUPS_CONST = {
  '1stMiller': {
    name: '1st Miller',
    label: '1st Miller',
    value: '1stMiller',
    color: 'magenta',
  },
  '2ndMiller': {
    name: '2nd Miller',
    label: '2nd Miller',
    value: '2ndMiller',
    color: 'green',
  },
  headMiller: {
    name: 'Head Miller',
    label: 'Head Miller',
    value: 'headMiller',
    color: 'cyan',
  },
  assistantHeadMiller: {
    name: 'Assistant Head Miller',
    label: 'Assistant Head Miller',
    value: 'assistantHeadMiller',
    color: 'geekblue',
  },
  electrician: {
    name: 'Electrician',
    label: 'Electrician',
    value: 'electrician',
    color: 'magenta',
  },
  millwright: {
    name: 'Millwright',
    label: 'Millwright',
    value: 'millwright',
    color: 'volcano',
  },
  qaTechnician: {
    name: 'QA Technician',
    label: 'QA Technician',
    value: 'qaTechnician',
    color: 'purple',
  },
  sanitationTechnician: {
    name: 'Sanitation Technician',
    label: 'Sanitation Technician',
    value: 'sanitationTechnician',
    color: 'volcano',
  },
  maintenanceManager: {
    name: 'Maintenance Manager',
    label: 'Maintenance Manager',
    value: 'maintenanceManager',
    color: 'geekblue',
  },
  wheatReceiver: {
    name: 'Wheat Receiver',
    label: 'Wheat Receiver',
    value: 'wheatReceiver',
    color: 'magenta',
  },
  maintenanceSupervisor: {
    name: 'Maintenance Supervisor',
    label: 'Maintenance Supervisor',
    value: 'maintenanceSupervisor',
    color: 'purple',
  },
}

export const ROLES_CONST = {
  Admin: {
    name: 'Admin',
    color: 'gold',
    value: 'Admin',
  },
  Driver: {
    name: 'Driver',
    color: 'purple',
    value: 'Driver',
  },
  Logistics: {
    name: 'Logistics',
    color: 'volcano',
    value: 'Logistics',
  },
  Customer: {
    name: 'Customer',
    color: 'magenta',
    value: 'Customer',
  },
  OrderManager: {
    name: 'Order manager',
    color: 'geekblue',
    value: 'OrderManager',
  },
  OrderPicker: {
    name: 'Order picker',
    color: 'cyan',
    value: 'Order picker',
  },
  OrderInspector: {
    name: 'Order inspector',
    color: 'green',
    value: 'OrderInspector',
  },
  HamUtility: {
    name: 'Ham Utility',
    color: 'magenta',
    value: 'HamUtility',
  },
  QAManager: {
    name: 'QA Manager',
    color: 'gold',
    value: 'QAManager',
  },
  TaskUser: {
    name: 'Task User',
    color: 'green',
    value: 'TaskUser',
    groups: getArrFromObj(TASK_USER_GROUPS_CONST),
  },
}

export const ROLE_GROUPS_CONST = ['TaskUser']
