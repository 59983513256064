import loadable from './utils/functions/loadable'
import {
  HomeOutlined,
  LoginOutlined,
  UserOutlined,
  KeyOutlined,
  CalendarOutlined,
  LockOutlined,
  LayoutOutlined,
  FileSearchOutlined,
  BarChartOutlined,
  FolderOpenOutlined,
  FileTextOutlined,
  CarOutlined,
  DollarOutlined,
  ProfileOutlined,
  FundViewOutlined,
} from '@ant-design/icons'
import {
  CustomerIcon,
  TrailerIcon,
  BoxArchiveIcon,
  CarWashIcon,
} from './assets/icons'

const LoginPage = loadable(() => import('./views/LoginPage'))
const UsersPage = loadable(() => import('./views/UsersPage'))
const ActivateAccountPage = loadable(() =>
  import('./views/ActivateAccountPage'),
)
const CustomersPage = loadable(() => import('./views/CustomersPage'))
const AssetsPage = loadable(() => import('./views/AssetsPage'))
const DashboardPage = loadable(() => import('./views/DashboardPage'))
const LoadsPage = loadable(() => import('./views/LoadsPage'))
const LoadDetailPage = loadable(() => import('./views/LoadDetailPage'))
const OrderDetailPage = loadable(() => import('./views/OrderDetailPage'))
const InventoryPage = loadable(() => import('./views/InventoryPage'))
const SchedulePage = loadable(() => import('./views/SchedulePage'))
const ScheduleDriverPage = loadable(() =>
  import('./views/ScheduleDriverPage/ScheduleDriverPage'),
)
const ForgotPasswordPage = loadable(() => import('./views/ForgotPasswordPage'))
const MyAccountPage = loadable(() => import('./views/MyAccountPage'))
const DocumentsPage = loadable(() => import('./views/DocumentsPage'))
const DocumentsEditorPage = loadable(() =>
  import('./views/DocumentsEditorPage/DocumentsEditorPage'),
)
const WashCyclePage = loadable(() => import('./views/WashCyclePage'))
const LogisticDashboardPage = loadable(() =>
  import('./views/LogisticDashboardPage/LogisticDashboardPage'),
)
const HomePage = loadable(() => import('./views/HomePage/HomePage'))
const PayrollPage = loadable(() => import('./views/PayrollPage/PayrollPage'))
const TasksPage = loadable(() => import('./views/TasksPage/TasksPage'))
const LogsPage = loadable(() => import('./views/LogsPage/LogsPage'))

export const activateAccountRoute = '/auth/activateAccount/:token'
export const loginRoute = '/auth/login'

export const mainDefaultRoute = '/main/home'
export const usersRoute = '/main/users'
export const myAccountRoute = '/main/myAccount'
export const customersRoute = '/main/customers'
export const assetsRoute = '/main/assets'
export const scheduleRoute = '/main/schedule'
export const forgotPasswordRoute = '/auth/forgotpassword'
export const dashboardRoute = '/main/dashboard'
export const loadsRoute = '/main/loads'
export const inventoryRoute = '/main/inventory'
export const loadDetailRoute = (loadId, orderBol = '') =>
  `/main/loadDetail/${loadId}/${orderBol}`
export const orderDetailRoute = (orderId) => `/main/orderDetail/${orderId}`
export const documentsRoute = '/main/documents'
export const editDocumentRoute = (documentId) =>
  `/main/documentEditor/${documentId}`
export const createDocumentRoute = '/main/documentEditor'
export const washCycleRoute = (assetId) => `/main/washCycle/${assetId}`
export const viewAsDriverRoute = (initials) => `/main/viewAsDriver/${initials}`
export const logisticDashboardRoute = '/main/logisticDashboard'
export const payrollRoute = (initials = '') => `/main/payroll/${initials}`
export const tasksRoute = '/main/tasks'
export const logsRoute = '/main/logs'

const dashboardRouteObj = {
  name: 'Dashboard',
  path: dashboardRoute,
  icon: <LayoutOutlined />,
  component: DashboardPage,
}

const loadsRouteObj = {
  name: 'Loads',
  path: loadsRoute,
  icon: <BoxArchiveIcon />,
  component: LoadsPage,
}

const loadDetailRouteObj = {
  name: 'Load details',
  path: loadDetailRoute(':id', ':orderBol?'),
  icon: <FileSearchOutlined />,
  component: LoadDetailPage,
  hidden: true,
}

const homeRouteObj = {
  name: 'Home',
  path: mainDefaultRoute,
  icon: <HomeOutlined />,
  component: HomePage,
}

const usersRouteObj = {
  name: 'Users',
  path: usersRoute,
  icon: <UserOutlined />,
  component: UsersPage,
}

const customersRouteObj = {
  name: 'Customers',
  path: customersRoute,
  icon: <CustomerIcon />,
  component: CustomersPage,
}

const assetsRouteObj = {
  name: 'Assets',
  path: assetsRoute,
  icon: <TrailerIcon />,
  component: AssetsPage,
}

const scheduleRouteObj = {
  name: 'Schedule',
  path: scheduleRoute,
  icon: <CalendarOutlined />,
  component: SchedulePage,
}

const scheduleDriverRouteObj = {
  name: 'Schedule',
  path: scheduleRoute,
  icon: <CalendarOutlined />,
  component: ScheduleDriverPage,
}

const orderDetailRouteObj = {
  name: 'Order details',
  path: orderDetailRoute(':id'),
  icon: <FileSearchOutlined />,
  component: OrderDetailPage,
  hidden: true,
}

const inventoryObj = {
  name: 'Inventory',
  path: inventoryRoute,
  icon: <BarChartOutlined />,
  component: InventoryPage,
}

const myAccountObj = {
  name: 'My account',
  path: myAccountRoute,
  icon: <UserOutlined />,
  component: MyAccountPage,
  hidden: true,
}

const documentsRouteObj = {
  name: 'Documents',
  path: documentsRoute,
  icon: <FolderOpenOutlined />,
  component: DocumentsPage,
}

const editDocumentRouteObj = {
  name: 'Edit document',
  path: editDocumentRoute(':id'),
  icon: <FileTextOutlined />,
  component: DocumentsEditorPage,
  hidden: true,
}

const createDocumentRouteObj = {
  name: 'Create document',
  path: createDocumentRoute,
  icon: <FileTextOutlined />,
  component: DocumentsEditorPage,
  hidden: true,
}

const washCycleRouteObj = {
  name: 'Wash cycle',
  path: washCycleRoute(':assetId'),
  icon: <CarWashIcon />,
  component: WashCyclePage,
  hidden: true,
}

const viewAsDriverRouteObj = {
  name: 'View as driver',
  path: viewAsDriverRoute(':initials?'),
  icon: <CarOutlined />,
  component: ScheduleDriverPage,
  hidden: true,
}

const logisticDashboardObj = {
  name: 'Logistic dashboard',
  path: logisticDashboardRoute,
  icon: <LayoutOutlined />,
  component: LogisticDashboardPage,
}

const payrollRouteObj = {
  name: 'Payroll',
  path: payrollRoute(':initials?'),
  pathLink: payrollRoute(),
  icon: <DollarOutlined />,
  component: PayrollPage,
}

const tasksRouteObj = {
  name: 'Tasks',
  path: tasksRoute,
  icon: <ProfileOutlined />,
  component: TasksPage,
}

const logsRouteObj = {
  name: 'Logs',
  path: logsRoute,
  icon: <FundViewOutlined />,
  component: LogsPage,
}

export const mainRoutes = {
  Admin: [
    homeRouteObj,
    scheduleRouteObj,
    logisticDashboardObj,
    payrollRouteObj,
    tasksRouteObj,
    customersRouteObj,
    assetsRouteObj,
    usersRouteObj,
    dashboardRouteObj,
    loadsRouteObj,
    loadDetailRouteObj,
    inventoryObj,
    documentsRouteObj,
    orderDetailRouteObj,
    myAccountObj,
    editDocumentRouteObj,
    createDocumentRouteObj,
    washCycleRouteObj,
    viewAsDriverRouteObj,
    logsRouteObj,
  ],
  Driver: [
    homeRouteObj,
    scheduleDriverRouteObj,
    myAccountObj,
    payrollRouteObj,
    viewAsDriverRouteObj,
  ],
  Logistics: [
    homeRouteObj,
    customersRouteObj,
    assetsRouteObj,
    scheduleRouteObj,
    myAccountObj,
  ],
  Customer: [homeRouteObj, myAccountObj],
  OrderManager: [
    homeRouteObj,
    dashboardRouteObj,
    loadsRouteObj,
    loadDetailRouteObj,
    orderDetailRouteObj,
    inventoryObj,
    myAccountObj,
  ],
  OrderPicker: [homeRouteObj, dashboardRouteObj, inventoryObj, myAccountObj],
  OrderInspector: [homeRouteObj, dashboardRouteObj, inventoryObj, myAccountObj],
  HamUtility: [
    homeRouteObj,
    scheduleRouteObj,
    myAccountObj,
    logisticDashboardObj,
    viewAsDriverRouteObj,
    assetsRouteObj,
    payrollRouteObj,
  ],
  QAManager: [homeRouteObj, usersRouteObj, tasksRouteObj],
  TaskUser: [homeRouteObj, tasksRouteObj],
}

/**
 * Use mx-auto on icons to prevent responsive errors
 */
export const authRoutes = [
  {
    name: 'Login',
    path: loginRoute,
    icon: <LoginOutlined className='mx-auto' />,
    component: LoginPage,
    // hidden: true
  },
  {
    name: 'Forgot password?',
    path: forgotPasswordRoute,
    icon: <LockOutlined className='mx-auto' />,
    component: ForgotPasswordPage,
    hidden: true,
  },
  {
    name: 'Activate account',
    path: activateAccountRoute,
    icon: <KeyOutlined className='mx-auto' />,
    component: ActivateAccountPage,
    hidden: true,
  },
]
